<template>
    <tab :index="tabIndex">
        <slider-section v-if="listHeroBanner.length >0" :listHeroBanner="listHeroBanner"/>
        <hotmatch-section :items="hotmatchData" />
        <eventBanner v-if="$isEnableThemeWorldcup() && $device.isMobile" />
        <game-section/>
        <gamehot-section/>
        <slot-section :items="topwinData"/>
        <new-section :items="categoriesData" />
        <snowEvent v-if="$isEnableThemeNoel()" />
        <holidayEvent v-if="$isEnableThemeHoliday()" />
    </tab>
</template>

<script>
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-unresolved
import holidayEvent from '@/components/event/holiday/AnimateNewYear.vue';
import tab from '../tab.vue';
import sliderSection from './home/slider.vue';
import hotmatchSection from './home/hot-match.vue';
import gameSection from './home/games.vue';

export default {
    props: ['tabIndex'],
    components: {
        tab,
        sliderSection,
        hotmatchSection,
        gameSection,
        holidayEvent,
        gamehotSection: () => import('@/components/desktop/home/game-hot.vue'),
        slotSection: () => import('@/components/desktop/home/slots.vue'),
        newSection: () => import('@/components/desktop/home/news.vue'),
        snowEvent: () => import('@/components/event/noel/snow-event.vue'),
        eventBanner: () => import('@/components/event/worldcup/banner-home.vue')
    },
    head() {
        return {

        };
    },
    data() {
        return {
            hotmatchData: [],
            categoriesData: [],
            topwinData: [],
            listHeroBanner: []
        };
    },
    methods: {
        async getBanners() {
            const todayFormat = dayjs().format('DD/MM/YYYY HH:mm');
            try {
                const result = await this.$heroBannerApi.getHeroBanner();
                if (result.status === 'OK') {
                    if (result.result) {
                        result.result.forEach((item) => {
                            if (item.matchId && item.leagueId) {
                                item.link = `/the-thao-chau-a/tran-dau?id=${item.matchId}`;
                            } else {
                                item.link = item.path;
                            }
                            item.link_type = item.type;
                            // eslint-disable-next-line no-self-assign
                            item.matchId = item.matchId;
                            // eslint-disable-next-line no-self-assign
                            item.leagueId = item.leagueId;
                            if (item.expired_date) {
                                if (item.expired_date > todayFormat) {
                                    this.listHeroBanner.push(item);
                                }
                            } else {
                                this.listHeroBanner.push(item);
                            }
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getHotMatch() {
            try {
                const res = await this.$hotmatchApi.index();
                if (res.status === 'OK') {
                    this.hotmatchData = res.data;
                }
            } catch (e) {
                this.formError = e.message;
            }
        },
        async getCategory() {
            try {
                const res = await this.$postApi.getCategories();
                if (res.status === 'OK') {
                    res.categories.map(async item => {
                        const {
                            subCats, id, name, alias
                        } = item;
                        if (subCats && !subCats.length) {
                            return {
                                categoriesData: await this.handleGetByCatIds({ id }, name, alias), ...item
                            };
                        }
                        const catIds = subCats.map(m => m.id);
                        return {
                            categoriesData: await this.handleGetByCatIds({ id: catIds.toString() }, name, alias), ...item
                        };
                    });
                }
            } catch (e) {
                this.formError = e.message;
            }
        },
        async handleGetByCatIds({ id }, name, alias) {
            const data = await this.$postApi.getByCatIds(id, { limit: 1, offset: 0 });
            data.posts[0].category = name;
            data.posts[0].category_alias = alias;
            this.categoriesData.push(data.posts[0]);
        },
        async getTopWin() {
            try {
                const res = await this.$topwinApi.index();
                if (res.status === 'OK') {
                    this.topwinData = res.data;
                }
            } catch (e) {
                this.formError = e.message;
            }
        },
        async getWallet() {
            try {
                const res = await this.$userApi.getWallet();
                if (res.status === 'OK') {
                    this.$store.dispatch('updateWallet', {
                        oldBalance: res.data.balance,
                        balance: res.data.balance,
                        oldBalanceSub: res.data.balanceSub,
                        balanceSub: res.data.balanceSub,
                        maxBalance: res.data.balanceAll
                    });
                }
            } catch (e) {
                this.formError = e.message;
            }
        },
        async getSpinsCount() {
            if (this.$store.state.user) {
                try {
                    const { data: { remainingTurn } } = await this.$eventWheelDefaultApi.d_spinsCount(this.$store.state.user.username);
                    this.$store.commit('mutate', {
                        property: 'spinsCount',
                        with: remainingTurn
                    });
                } catch (e) {
                    this.formError = e.message;
                }
            }
        }
    },
    mounted() {
        if (this.$isEnableEventWheel() || this.$isEnableThemeSeagame()) {
            this.getSpinsCount();
        }
        this.$nextTick(async () => {
            this.getBanners();
            this.getHotMatch();
            this.getCategory();
            this.getWallet();
            // this.getTopWin();
            this.$store.dispatch('getJackpot');
            this.jackPotInterval = setInterval(async () => {
                this.$store.dispatch('getJackpot');
            }, 5000);
            this.$opacity(350, '.wrap-home');
        });
    },
    destroyed() {
        clearInterval(this.jackPotInterval);
    }
};
</script>
