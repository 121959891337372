<template>
    <div>
        <div class="spin-event">
            <div class="spin-event__title spin-event__title--top">
                <template v-if="$isEnableThemeHoliday()">
                    <d-img class="spin-event__title--pc" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/wheel/spin_title.png?v=1`" alt=""/>
                    <d-img class="spin-event__title--mb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/wheel/spin_title_mb.png?v=1`" alt=""/>
                </template>
              <template v-if="$isEnableThemeNoel()">
                    <d-img class="spin-event__title--pc" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/spin_title.png?v=1`" alt=""/>
                    <d-img class="spin-event__title--mb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/spin_title_mb.png?v=1`" alt=""/>
                </template>
                <template v-else>
                    <d-img class="spin-event__title--pc" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/spin_title.png?v=1`" alt=""/>
                    <d-img class="spin-event__title--mb" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/spin_title_mb.png?v=1`" alt=""/>
                </template>
                <p v-if="currentUser" class="pc-only">Bạn có <b>{{ spinsCount }} lượt</b> quay</p>
                <p v-else class="pc-only">
                    <a href="javascript:void(0)" @click="$bvModal.show('loginModal')">Đăng nhập</a> để tham gia
                </p>
            </div>
            <div class="spin-event__body" :class="{'spin-event__body_noel':$isEnableThemeNoel()}">
                <div class="lucky-custom">
                    <div id="luckywheel" class="hc-luckywheel noselect">
                        <div class="hc-luckywheel-container">
                            <div class="hc-luckywheel-inner">
                                <canvas
                                        id="lucky-wheel"
                                        class="hc-luckywheel-canvas"
                                        width="332"
                                        height="332"
                                >Vòng Xoay May Mắn</canvas>
                                <button class="hc-luckywheel-btn" :disabled="disableSpinWheel" @click="handleSpinTurn">
                                </button>
                                <template v-if="$isEnableThemeHoliday()">
                                    <d-img class="nodrag arrow" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/wheel/arrow.png`" alt="arrow"/>
                                    <d-img class="leaf-wheel" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/wheel/leaf-wheel.png`" alt="leaf"/>
                                </template>
                              <template v-if="$isEnableThemeNoel()">
                                    <d-img class="nodrag arrow" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/spin/arrow.png`" alt="arrow"/>
                                    <d-img class="leaf-wheel" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/spin/leaf-wheel.png`" alt="leaf"/>
                                </template>
                                <template v-else>
                                    <d-img class="nodrag arrow" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/arrow.png`" alt="arrow"/>
                                    <d-img class="leaf-wheel" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/leaf-wheel.png`" alt="leaf"/>
                                </template>
                            </div>
                            <div class="hc-luckywheel-spin-wrapper">
                                <div class="hc-luckywheel-spin">
                                    <button :disabled="disableSpinWheel" class="btn hc-luckywheel-spin__one" @click="handleSpinTurn">
                                        <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/spin-button/quay-mot-luot-text.png`" alt="button"/>
                                    </button>
                                    <button :disabled="disableSpinWheel" class="btn hc-luckywheel-spin__ten" @click="handleSpinTenTurn">
                                        <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/spin-button/quay-muoi-luot-text.png`" alt="button"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spin-event__title spin-event__title--bottom sp-only">
                        <p v-if="currentUser">Bạn có <b>{{ spinsCount }} lượt</b> quay</p>
                        <p v-else>
                            <a href="javascript:void(0)" @click="$bvModal.show('loginModal');">
                                Đăng nhập
                            </a> để tham gia
                        </p>
                    </div>
                </div>
                <div :class="{'spin-event__rules-event-noel': $isEnableThemeNoel()}"
                    class="spin-event__rules">
                    <div class="spin-event__content">
                        <p class="spin-event__content--title">Số lượng giải thưởng còn lại</p>
                        <div class="spin-event__award">
                            <div class="spin-event__award--item spin-event__award--item--special">
                                <div class="spin-event__award--item__wrap">
                                    <d-img v-if="$isEnableThemeHoliday()" class="spin-event__award--item--gift" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/wheel/spin_gift.png`" alt="gift"/>
                                    <d-img v-else class="spin-event__award--item--gift" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/wheel/spin_gift.png`" alt="gift"/>
                                    <p><b>{{ eventWheelInfo.amountSpecial }}</b>Giải đặc biệt</p>
                                </div>
                            </div>
                            <div class="spin-event__award--item" v-for="spin in spinsReward.slice()" :key="spin.type">
                                <div class="spin-event__award--item__wrap">
                                    <p><b>{{ eventWheelInfo.rewardMapping[spin.type].name }}</b>{{ spin ? spin.remain : '0' }} giải</p>
                                </div>
                            </div>
                        </div>
                        <div class="spin-event__help d-flex flex-column justify-content-between pc-only">
                            <p class="mb-2">Cược <b>2000 D = 1 lượt quay</b></p>
                            <div class="d-flex justify-content-between w-100">
                                <a @click="$bvModal.show('rulesConditionModal')" href="javascript:void(0)" class="button show-desktop">
                                    Thể lệ
                                </a>
                                <a @click="$bvModal.show('historyPredictionModal')"
                                   href="javascript:void(0)"
                                   class="button button-history show-desktop"
                                   v-if="currentUser"
                                >
                                    Lịch sử nhận thưởng
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spin-event__turn-spin">
                    <div class="spin-event__help turn d-flex align-items-center justify-content-between sp-only">
                        <p>Cược <b>2000 D = 1 lượt quay</b></p>
                    </div>
                    <div class="d-flex">
                        <a @click="$bvModal.show('historyPredictionModal')"
                           href="javascript:void(0)"
                           class="button show-desktop mr-2"
                           v-if="currentUser"
                        >
                            Lịch sử
                        </a>
                        <a @click="$bvModal.show('rulesConditionModal')"
                           href="javascript:void(0)"
                           class="button show-desktop"
                        >
                            THỂ LỆ
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" id="lucky_id" value="" />
        <input type="hidden" id="lucky_message" value=""/>
        <client-only>
            <WinPrizesModal
                v-if="winPrizesNumber && !wheelSpinning"
                :win-prizes-number="winPrizesNumber" />
        </client-only>
    </div>
</template>
<script>
import eventWheelMixin from '@/mixins/event/wheel-default-mixin';
import { eventWheelInfo } from '@/resources/event-wheel';
import WinPrizesModal from '@/components/event/wheel/modal/win-prizes-modal.vue';

export default {
    mixins: [eventWheelMixin],
    components: {
        WinPrizesModal
    },
    data() {
        return {
            eventWheelInfo
        };
    }
};
</script>
