<template>
    <div class="wrap-promotion">
        <div class="promotion">
            <div class="heading h2 section-title">
                <img v-if="$isEnableThemeHoliday()" class="lanterns" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/home/lanterns-promotion-left.png`" alt="lanterns"/>
                <nuxt-picture class="line-top" src="/assets/img/match/line.svg" loading="lazy"  alt="line"/>
                <p>KHUYẾN MÃI HẤP DẪN</p>
                <nuxt-picture class="line-bottom" src="/assets/img/match/line.svg" loading="lazy"  alt="line"/>
                <img v-if="$isEnableThemeHoliday()" class="lanterns right" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/home/lanterns-promotion-right.png`" alt="lanterns"/>
            </div>
            <div id="PromoSlider" class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="row">
                            <div class="col-4 col-lg-4 item-img">
                                <n-link to="/khuyen-mai?type=welcome">
                                    <img v-if="$isEnableThemeNoel()" src="/assets/img/event/noel/home/promotion.png" loading="lazy"  alt="sale110"/>
                                    <img v-else src="/assets/img/match/promotion.png" loading="lazy"  alt="sale110"/>
                                </n-link>
                            </div>
                            <div class="col-4 col-lg-4 item-img">
                                <n-link to="/khuyen-mai?type=promotion">
                                    <img v-if="$isEnableThemeNoel()" src="assets/img/event/noel/home/promotion-1.png" loading="lazy"  alt="sale30"/>
                                    <img v-else src="/assets/img/match/promotion-1.png" loading="lazy"  alt="sale30"/>
                                </n-link>
                            </div>
                            <div class="col-4 col-lg-4 item-img">
                                <n-link to="/khuyen-mai?type=commission">
                                    <img v-if="$isEnableThemeNoel()" src="assets/img/event/noel/home/promotion-2.png" loading="lazy"  alt="sale15"/>
                                    <img v-else src="/assets/img/match/promotion-2.png" loading="lazy"  alt="sale15"/>
                                </n-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination swiper-pagination--banner"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        initSliderPromo() {
            this.$swiper('#PromoSlider', {
                autoplay: {
                    delay: 3000
                },
                loop: false

            });
        }
    },
    mounted() {
        this.$nextTick(async () => {
            this.initSliderPromo();
        });
    }
};
</script>
