<template>
  <b-modal
      id="winPrizesModal"
      dialog-class="modal-dialog-event"
      :content-class="['modal-wheel modal-wheel--win-prizes', {'holiday-custom': $isEnableThemeHoliday()}, {'--noel': $isEnableThemeNoel()}]"
      hide-footer
      ok-only
      no-stacking
      centered
  >
    <div class="modal-wrapper">
      <div class="modal-top">
        <h3 class="modal-top__title text-gradient">chúc mừng</h3>
        <p class="modal-top__text">Bạn là người chơi may mắn nhận được</p>
      </div>
      <div class="modal-inner text-gradient">{{ winPrizesNumberFormat | formatCurrency }} VNĐ</div>
      <div class="modal-button">
        <a
            @click="$bvModal.hide('winPrizesModal')"
            data-toggle="modal" href="javascript:void(0)"
            class="btn-login-monile button background-2 padding-2 hidden"
        >
          QUAY LẠI
        </a>
        <a
            @click="moveTo('/the-thao-chau-a')"
            data-toggle="modal"
            href="javascript:void(0)"
            class="button show-desktop"
        >
          CƯỢC NGAY
        </a>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
    props: {
        winPrizesNumber: {
            type: Number,
            required: true
        }
    },
    computed: {
        winPrizesNumberFormat() {
            return this.winPrizesNumber * 1000;
        }
    },
    methods: {
        moveTo(link) {
            this.$bvModal.hide('winPrizesModal');
            this.$openLink(link, {
                loginRequired: false,
                newTab: false
            });
        }
    }
};
</script>
