<template>
    <div class="wrap-home">
        <component :is="component" :tabIndex="tabIndex"/>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import base from '~/components/base.vue';
// eslint-disable-next-line import/no-unresolved
import desktop from '~/components/desktop/index.vue';

export default {
    extends: base,
    components: {
        desktop
    },
    head() {
        return {
        };
    },
    data() {
        return {
            tabIndex: this.$getRoute(this.$route).path
        };
    },
    methods: {
        showUpdateUpdateDomainModal() {
            const enableDomainUpdate = process.env.ENABLE_DOMAIN_UPDATE;
            if (enableDomainUpdate === '1') {
                this.$nextTick(() => {
                    this.$bvModal.show('updateDomainModal');
                });
            }
            this.$store.commit('mutate', {
                property: 'showMaintain',
                with: true
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (process.browser && !this.$store.state.showMaintain) {
                this.showUpdateUpdateDomainModal();
            }
            if (this.$store.state.user !== null && !this.$store.state.luckySpin) {
                this.$store.dispatch('getLuckySpin');
            }
        });
    }
};
</script>
