<template>
  <section class="section section--slider">
    <div>
      <b-carousel
        id="slider-home"
        class="slider-home"
        fade
        img-width="100%"
        img-height="auto"
        indicators
        controls
        :interval="6000"
      >
        <template v-for="(item, index) in slideVideoFormat">
          <b-carousel-slide :key="index">
            <template v-slot:img>
              <a
                @click.prevent="moveTo(item)"
                href="javascript:void(0)"
                class="default-img"
                :class="{'default-video': item.isVideo}">
                <template v-if="item.isVideo">
                  <video v-if="item.video" width="100%" height="100%" :src="item.video" :poster="item.video" class="d-block w-100 show-pc"
                         playsinline="" autoplay="autoplay" muted="muted" loop="loop"></video>
                  <video v-if="item.video_mobile" width="100%" height="100%" :src="item.video_mobile" :poster="item.video_mobile" class="d-block w-100 show-mb"
                         playsinline="" autoplay="autoplay" muted="muted" loop="loop"></video>
                  <img v-if="item.image_mobile" class="d-block img-fluid w-100 show-mb" :src="`${item.image_mobile}`" :alt="item.name">
                </template>
                <template v-else>
                  <img class="d-block img-fluid w-100 show-pc" :src="`${item.imgSrc}`" :alt="item.name">
                  <img class="d-block img-fluid w-100 show-mb" :src="`${item.imgSrcMobile}`" :alt="item.name">
                </template>
              </a>
              <a
                v-if="item.showButtonModal"
                class="buton-slide"
                @click="showModalNoel()"
                href="javascript:void(0)">
                <d-img :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/noel/buton-detail.png`"></d-img>
              </a>
            </template>
          </b-carousel-slide>
        </template>
      </b-carousel>
    </div>
  </section>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import globalFuncMixin from '@/mixins/global-func-mixin';

export default {
    mixins: [globalFuncMixin],
    props: ['listHeroBanner'],
    computed: {
        user() {
            return this.$store.state.user;
        },
        slideVideoFormat() {
            return [...this.listHeroBanner];
        }
    },
    methods: {
        moveTo(item) {
            this.$_globalMixin_moveTo(item);
        },
        showModalNoel() {
            this.$bvModal.show('rulesConditionModal');
        }
    },
    destroyed() {}
};
</script>
