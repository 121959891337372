<template>
    <section class="section section--product">
    <b-container>
        <div class="section__title text-center">
            <div class="start-title">
                <img src="/assets/img/line-star.png" loading="lazy" alt="start"/>
            </div>
            <div class="heading h2"><span>BỘ GAMES</span></div>
        </div>
        <b-row class="row-menu">
            <b-col cols="3" lg="2" class="col-menu" v-for="(item, index) in menuItems" :key="index">
                <div class="product__item">
                    <a href="javascript:void(0)" @click.prevent="moveTo(item)">
                        <div class="product__item-thumb default-img">
                            <s-img v-if="item.label === $LABEL_TYPE.LABEL_EVENT" class="label"
                                   src="/assets/img/event/icon-event.svg" alt="event"/>
                            <img v-if="item.thumbEvent && $isEnableThemeWorldcup()" :src="item.thumbEvent" loading="lazy" :alt="item.title"/>
                            <img v-else :src="item.thumb" loading="lazy" :alt="item.title"/>
                        </div>
                        <div class="product__item-title text-center">{{item.title}}</div>
                    </a>
                </div>
            </b-col>
        </b-row>
    </b-container>
</section>
</template>
<script>
export default {
    data() {
        return {
            menuItems: [
                {
                    title: 'GAME BÀI',
                    link: '/tro-choi?tab=macau&type=game-bai',
                    thumb: '/assets/img/game/gamebai.png',
                    newTab: false
                },
                {
                    title: 'THỂ THAO ẢO',
                    link: '/the-thao-ao',
                    thumb: '/assets/img/game/thethaoao.png',
                    newTab: true
                },
                {
                    title: 'THỂ THAO',
                    link: '/the-thao',
                    thumb: '/assets/img/game/thethao.png',
                    thumbEvent: '/assets/img/game/thethao-event.png',
                    newTab: false,
                    label: this.$LABEL_TYPE.LABEL_EVENT
                },
                {
                    title: 'LIVE CASINO',
                    link: '/livecasino',
                    thumb: '/assets/img/game/livecasino.png',
                    newTab: false
                },
                {
                    title: 'KENO',
                    link: '/keno',
                    thumb: '/assets/img/game/keno.png',
                    newTab: true
                },
                {
                    title: 'NỔ HŨ',
                    link: '/tro-choi?tab=macau&type=no-hu',
                    thumb: '/assets/img/game/slots.png',
                    newTab: false
                },
                {
                    title: 'BẮN CÁ',
                    link: '/tro-choi?tab=macau&type=ban-ca',
                    thumb: '/assets/img/game/fish.png',
                    newTab: false
                },
                {
                    title: 'QUAY SỐ',
                    link: '/tro-choi?tab=macau&type=quay-so',
                    thumb: '/assets/img/game/lottery.png',
                    newTab: false
                },
                {
                    title: 'InGame',
                    link: '/tro-choi?tab=macau&type=ingame',
                    thumb: '/assets/img/game/ingame.png',
                    newTab: false
                },
                {
                    title: 'LÔ ĐỀ',
                    link: '/tro-choi-lo-de',
                    thumb: '/assets/img/game/lode.png',
                    newTab: false
                },
                {
                    title: 'NUMBERS GAME',
                    link: '/tro-choi-so',
                    thumb: '/assets/img/game/numbergame.png',
                    newTab: true
                },
                {
                    title: 'Table Games',
                    link: '/tro-choi?type=danh-bai',
                    thumb: '/assets/img/game/tablegame.png',
                    newTab: false
                }
            ]
        };
    },
    methods: {
        moveTo(item) {
            if (this.$isMobile()) {
                if (item.newTab) {
                    if (item.link === '/tro-choi-so') {
                        this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/numbergame`, {
                            loginRequired: false,
                            newTab: true
                        });
                    } else if (item.link === '/the-thao-so') {
                        this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/esports`, {
                            loginRequired: false,
                            newTab: true
                        });
                    } else if (item.link === '/the-thao-ao') {
                        this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=/vssport`, {
                            loginRequired: false,
                            newTab: true
                        });
                    } else {
                        this.$openLink(`${this.$store.state.mapDomain.GAME_URL}?url=${item.link}`, {
                            loginRequired: false,
                            newTab: true
                        });
                    }
                } else {
                    this.$router.push(item.link);
                }
            } else if (item.link === '/the-thao-so') {
                this.$openEsports();
            } else {
                this.$router.push(item.link);
            }
        }
    }
};
</script>
