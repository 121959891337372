<template>
    <component :is="component"/>
</template>

<script>

export default {
    components: {
    },
    head() {
        return {
        };
    },
    beforeCreate() {
        this.component = 'desktop';
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    }
};
</script>
