<template>
    <section class="section section--hotmatch">
        <img v-if="$isEnableThemeNoel()" class="event-leaf bottom-mb left bottom width-1" src="/assets/img/event/noel/home/leaf-left.svg" alt="leaf">
        <b-container>
            <div v-if="$isEnableEventWheel()" class="row flex-row-reverse align-items-center">
                <div class="col-xl-6">
                    <WheelEvent />
                </div>
                <div class="col-xl-6">
                    <BlockMatch :items="items" />
                    <Promotion />
                </div>
            </div>
            <div v-else class="row">
                <div class="col-lg-6">
                    <BlockMatch :items="items" />
                </div>
                <div class="col-lg-6">
                    <Promotion />
                </div>
            </div>
        </b-container>
        <img v-if="$isEnableThemeNoel()" class="event-leaf bottom-mb width-2" src="/assets/img/event/noel/home/leaf-right.svg" alt="leaf">
    </section>
</template>
<script>
import BlockMatch from '@/components/desktop/home/block-match'
import Promotion from '@/components/desktop/home/promotion'
import WheelEvent from '@/components/event/wheel/wheel-event'

export default {
    props: ['items'],
    components: {
        BlockMatch,
        Promotion,
        WheelEvent
    }
};
</script>
