import * as Winwheel from 'static/assets/js/event/wheel/WinWheel';
import { loadExternalScript } from '@/helper';
import { PRIZE_OPTIONS } from '@/config/constants';
import { eventWheelInfo } from '@/resources/event-wheel';

export default {
    data() {
        return {
            winWheelOption: null,
            theWheel: null,
            wheelSpinning: false,
            WinWheelOptions: {
                canvasId: 'lucky-wheel',
                InnerRadius: 330,
                outerRadius: 330,
                centerX: 165, // correctly position the wheel
                centerY: 165,
                drawMode: 'image',
                numSegments: 12,
                rotationAngle: -15,
                responsive: true,
                animation: {
                    type: 'spinToStop',
                    duration: 20,
                    spins: 20,
                    direction: 'anti-clockwise',
                    callbackFinished: this.onFinishSpin
                }
            },
            segments: [
                { text: PRIZE_OPTIONS.GUESS },
                { text: PRIZE_OPTIONS.LUCKY },
                { text: PRIZE_OPTIONS.D_5TR },
                { text: PRIZE_OPTIONS.D_10 },
                { text: PRIZE_OPTIONS.LUCKY },
                { text: PRIZE_OPTIONS.D_10 },
                { text: PRIZE_OPTIONS.D_20 },
                { text: PRIZE_OPTIONS.D_10 },
                { text: PRIZE_OPTIONS.LUCKY },
                { text: PRIZE_OPTIONS.D_50 },
                { text: PRIZE_OPTIONS.D_20 },
                { text: PRIZE_OPTIONS.LUCKY }
            ],
            indicatedSegmentNumber: 0,
            winPrizesNumber: 0,
            countSpin: 0,
            prizeNumber: null,
            initRepeatSpin: false,
            spinsReward: [],
            eventWheelInfo
        };
    },
    computed: {
        disableSpinWheel() {
            if (this.currentUser) {
                return this.initRepeatSpin || this.wheelSpinning || this.spinsCount === 0;
            }
            return false;
        },
        currentUser() {
            return this.$store.state.user;
        },
        spinsCount() {
            return this.$store.state.spinsCount;
        },
        remainSpins() {
            return this.spinsCount < 10 ? this.spinsCount : 10;
        },
        prizeNumberList() {
            return this.$store.state.prizeNumberList;
        }
    },
    methods: {
        async getSpinsTurn(spin = 1) {
            if (this.currentUser) {
                try {
                    // const { data } = await new Promise(resolve => setTimeout(resolve({ status: 'OK', data: [1, 2, 3, 4, 5, 6, 10, 8, 9, 10] }), 1000)); // fake api
                    const { data } = await this.$eventWheelDefaultApi.d_spinTurn(spin);
                    const prizeNumberList = data.map(item => Object.values(this.eventWheelInfo.spinMap).find(spinData => spinData.type === item) || -1);
                    this.$store.commit('setPrizeNumberList', prizeNumberList);
                } catch (e) {
                    // // todo: fake
                    // const { data } = await new Promise(resolve => setTimeout(resolve({
                    //     status: 'OK',
                    //     data: [
                    //         eventWheelInfo.rewardMapping.money_ten.type,
                    //         eventWheelInfo.rewardMapping.money_fifty_thousand.type
                    //     ]
                    // }), 1000));
                    // const spinMap = Object.values(this.eventWheelInfo.spinMap);
                    // const prizeNumberList = data.map(item => spinMap.find(spinData => spinData.type === item) || -1);
                    // console.error('setPrizeNumberList', prizeNumberList);
                    // this.$store.commit('setPrizeNumberList', prizeNumberList);
                    console.error(e);
                }
            }
        },
        async getSpinsReward() {
            try {
                const { data } = await this.$eventWheelDefaultApi.d_spinsReward();
                this.spinsReward = data.filter(reward => reward.type !== 'lucky').map(reward => {
                    reward.name = eventWheelInfo.rewardMapping[reward.type]?.name;
                    reward.order = eventWheelInfo.rewardMapping[reward.type]?.order;
                    return reward;
                }).sort((a, b) => a.order - b.order);
            } catch (e) {
                // todo: fake
                // this.spinsReward = [
                //     {
                //         remains: 50,
                //         type: eventWheelInfo.rewardMapping.money_fifty_thousand.type
                //     }, {
                //
                //         remains: 100,
                //         type: eventWheelInfo.rewardMapping.money_five_thousand.type
                //     }, {
                //
                //         remains: 5000,
                //         type: eventWheelInfo.rewardMapping.money_fifty.type
                //     }, {
                //         remains: 15000,
                //         type: eventWheelInfo.rewardMapping.money_twenty.type
                //     }, {
                //
                //         remains: 25000,
                //         type: eventWheelInfo.rewardMapping.money_ten.type
                //     }
                // ];
                console.error(e);
            }
        },
        async getSpinsCount() {
            if (this.$store.state.user) {
                try {
                    const { data: { remainingTurn } } = await this.$eventWheelDefaultApi.d_spinsCount(this.$store.state.user.username);
                    this.$store.commit('mutate', {
                        property: 'spinsCount',
                        with: remainingTurn
                    });
                } catch (e) {
                    // todo: fakedata
                    // this.$store.commit('mutate', {
                    //     property: 'spinsCount',
                    //     with: 1
                    // });
                    this.formError = e.message;
                }
            }
        },
        loadExternalWinWheelLibrary() {
            loadExternalScript('assets/js/event/wheel/TweenMax.min.js');
        },
        resetWheel() {
            const theWheel = new Winwheel.Winwheel({
                ...this.WinWheelOptions,
                numSegments: 12,
                segments: this.segments
            });
            if (this.wheelSpinning) {
                this.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
            }
            theWheel.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
            const canvas = document.getElementById('lucky-wheel');
            if (canvas) {
                const ctx = canvas.getContext('2d');
                const image = new Image();
                image.onload = function () {
                    if (ctx) {
                        ctx.imageSmoothingQuality = 'high';
                        ctx.drawImage(image, 0, 0, 330, 330);
                        theWheel.wheelImage = image;
                        ctx.save();
                    }
                };
                if (this.$isEnableThemeHoliday()) {
                    image.src = '/assets/img/event/holiday/wheel/bg-quay.png';
                } else if (this.$isEnableThemeNoel()) {
                    image.src = '/assets/img/event/noel/spin/bg-quay.png';
                } else {
                    image.src = '/assets/img/event/wheel/bg-quay.png';
                }
                this.theWheel = theWheel;
                this.wheelSpinning = false; // Reset to false to power buttons and spin can be clicked again.
            }
        },
        initSpin() {
            this.resetWheel();
        },
        startSpin() {
            this.theWheel.stopAnimation(false);
            this.theWheel.rotationAngle = this.theWheel.rotationAngle % 360;
            this.theWheel.startAnimation();
            this.theWheel.animation.duration = 5;
            if (this.prizeNumberList.length > 1) {
                this.prizeNumber = this.prizeNumberList.map(p => {
                    p.order = eventWheelInfo.rewardMapping[p.type] ? eventWheelInfo.rewardMapping[p.type].order : 0;
                    return p;
                }).sort((a, b) => b.order - a.order)[0];
            } else {
                this.prizeNumber = this.initRepeatSpin ? this.prizeNumberList[this.countSpin - 1] : this.prizeNumberList[0];
            }
            // eslint-disable-next-line no-undef
            const wheelSegments = this.prizeNumber ? this.eventWheelInfo.prizes[this.prizeNumber.number] : false; // [6, 11]
            if (wheelSegments) {
                const wheelRandomNumber = Math.floor(Math.random() * wheelSegments.length); // 0..1
                this.indicatedSegmentNumber = wheelSegments[wheelRandomNumber];
                const stopAt = (30 * wheelSegments[wheelRandomNumber]) - 30 - 15; // center pin
                this.theWheel.animation.stopAngle = stopAt;
                this.theWheel.startAnimation();
                this.theWheel.animation.spins = this.theWheel.animation.spins + 1;
            }
        },
        async handleSpinTurn() {
            if (!this.currentUser) {
                this.$bvModal.show('loginModal');
                return;
            }
            this.startAnimationSpin();
            await this.getSpinsTurn();
            this.startSpin();
        },
        startAnimationSpin() {
            if (!this.currentUser) {
                this.$bvModal.show('loginModal');
                return;
            }
            this.wheelSpinning = true;
            this.theWheel.stopAnimation(false);
            this.theWheel.rotationAngle = this.theWheel.rotationAngle % 360;
            this.theWheel.startAnimation();
        },
        async handleSpinTenTurn() {
            if (!this.currentUser) {
                this.$bvModal.show('loginModal');
                return;
            }
            this.startAnimationSpin();
            this.countSpin = this.remainSpins;
            await this.getSpinsTurn(this.countSpin);
            this.initRepeatSpin = true;
            this.startSpin();
        },
        onFinishSpin() {
            const indicatedSegment = this.theWheel.segments[this.indicatedSegmentNumber];
            // disable button false when finish winwheel
            this.wheelSpinning = false;
            if (this.initRepeatSpin) {
                this.initRepeatSpin = false;
                this.getSpinsCount();
                this.getSpinsReward();
                this.$bvModal.show('completeSpinModal');
                return;
            }
            switch (indicatedSegment.text) {
                case PRIZE_OPTIONS.LUCKY:
                    this.$bvModal.show('luckyEventModal');
                    break;
                case PRIZE_OPTIONS.GUESS:
                    this.$nextTick(async () => {
                        this.$bvModal.show('predictedTeamModal');
                    });
                    break;
                default:
                    this.winPrizesNumber = indicatedSegment.text;
                    this.$nextTick(() => {
                        this.$bvModal.show('winPrizesModal');
                    });
                    break;
            }
            this.getSpinsCount();
            this.getSpinsReward();
        }
    },
    mounted() {
        this.initSpin();
        this.getSpinsReward();
        this.$nextTick(async () => {
            this.loadExternalWinWheelLibrary();
        });
    }
};
