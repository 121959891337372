<template>
    <div class="wrap-hotmatch" v-if="items && items.length > 0">
        <img v-if="$isEnableThemeHoliday()" class="flower-match" :src="`${$store.state.mapDomain.STATIC_URL}/assets/img/event/holiday/home/bg-event-match.png`" alt="flower"/>
        <div class="hot-matches">
            <div class="wrapper">
                <div class="matches">
                    <div class="matches__bg"></div>
                    <div id="hmLinks" class="matches__links swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item in items" :key="item.id">
                                <a @click.prevent="gotoMatch(item.match_id)" href="javascript:void(0)"></a>
                            </div>
                        </div>
                    </div>
                    <div class="matches__column matches__column--left">
                        <div class="matches__logo">
                            <div id="hmImgListLeft" class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide logo-team" v-for="item in items" :key="item.id">
                                        <img :src="item.teams[0].flag_thumbnail" loading="lazy"  :alt="item.teams[0].name"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="prevHmBtn" class="swiper-button-prev swiper-button-disabled"></div>
                        <div id="hmTeamNameListLeft" class="matches__team-name swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in items" :key="item.id">
                                    <p>{{item.teams[0].name}}</p>
                                </div>
                            </div>
                        </div>
                        <div id="hmTeamOddListLeft" class="matches__odds swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in items" :key="item.id">
                                    <span @click.prevent="gotoMatch(item.match_id)">{{item.hTeam.rate}}</span>
                                    <span @click.prevent="gotoMatch(item.match_id)">{{item.hTeam.odds}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="matches__column matches__column--center">
                        <div class="matches__play-now">
                            <span>Cược ngay</span>
                        </div>
                        <div id="hmTimeList" class="matches__date-time swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in items" :key="item.id">
                                    <span>{{ item.live && item.live === 'l' ? 'Đang diễn ra' : item.text_time}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="matches__wrap-main-slide">
                            <div id="hmMainSlider" class="matches__type swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="item in items" :key="item.id">
                                        <p>{{item.league_name_text}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="swiper-label-vs">VS</p>
                    </div>
                    <div class="matches__column matches__column--right">
                        <div class="matches__logo">
                            <div id="hmImgListRight" class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide logo-team" v-for="item in items" :key="item.id">
                                        <img :src="item.teams[1].flag_thumbnail" loading="lazy"  :alt="item.teams[1].name"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="nextHmBtn" class="swiper-button-next"></div>
                        <div id="hmTeamNameListRight" class="matches__team-name swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in items" :key="item.id">
                                    <p>{{item.teams[1].name}}</p>
                                </div>
                            </div>
                        </div>
                        <div id="hmTeamOddListRight" class="matches__odds swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in items" :key="item.id">
                                    <span @click.prevent="gotoMatch(item.match_id)">{{item.aTeam.rate}}</span>
                                    <span @click.prevent="gotoMatch(item.match_id)">{{item.aTeam.odds}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['items'],
    data() {
        return {
            hmImgListLeft: null,
            hmTeamNameListLeft: null,
            hmTeamOddListLeft: null,
            hmPlayBtnList: null,
            hmTimeList: null,
            hmMainSlider: null,
            hmImgListRight: null,
            hmTeamNameListRight: null,
            hmTeamOddListRight: null,
            spined: false,
            zoom: false
        };
    },
    watch: {
        items() {
            this.$nextTick(async () => {
                this.initSlider();
                const _hmMainSlider = this.hmMainSlider;
                if (this.items && this.items.length === 1) {
                    $('#nextHmBtn').addClass('swiper-button-disabled');
                }
                $('#nextHmBtn').click(() => {
                    _hmMainSlider.slideNext();
                    $('#prevHmBtn').removeClass('swiper-button-disabled');
                    if (_hmMainSlider.isEnd) {
                        $('#nextHmBtn').addClass('swiper-button-disabled');
                    }
                });
                $('#prevHmBtn').click(() => {
                    _hmMainSlider.slidePrev();
                    $('#nextHmBtn').removeClass('swiper-button-disabled');
                    if (_hmMainSlider.activeIndex === 0) {
                        $('#prevHmBtn').addClass('swiper-button-disabled');
                    }
                });
            });
        }
    },
    methods: {
        gotoMatch() {
            this.$tracking({ event: 'btnClick', btnName: 'hotMatch' });
            const activeItem = this.items[this.hmPlayBtnList.activeIndex];
            if (activeItem) {
                if (this.$store.state.device === 'mobile') {
                    const url = `/the-thao-chau-a/tran-dau?id=${activeItem.match_id}&lId=${activeItem.league_id}&l=${activeItem.live}`;
                    this.$openLink(url, {
                        loginRequired: false,
                        newTab: true
                    });
                } else {
                    this.$openLink(`/the-thao-chau-a/tran-dau?id=${activeItem.match_id}`, {
                        loginRequired: false,
                        newTab: false
                    });
                }
            }
        },
        initSlider() {
            if (this.hmMainSlider === null && this.items && this.items.length > 0) {
                this.hmMainSlider = this.$swiper('#hmMainSlider', {
                    direction: 'vertical',
                    allowTouchMove: false
                });

                this.hmImgListLeft = this.$swiper('#hmImgListLeft', {
                    direction: 'vertical',
                    allowTouchMove: false
                });

                this.hmTeamNameListLeft = this.$swiper('#hmTeamNameListLeft', {
                    direction: 'vertical',
                    allowTouchMove: false
                });

                this.hmTeamOddListLeft = this.$swiper('#hmTeamOddListLeft', {
                    direction: 'vertical',
                    allowTouchMove: false
                });

                this.hmPlayBtnList = this.$swiper('#hmLinks', {
                    direction: 'vertical',
                    allowTouchMove: false,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    }
                });
                this.hmTimeList = this.$swiper('#hmTimeList', {
                    direction: 'vertical',
                    allowTouchMove: false
                });
                this.hmImgListRight = this.$swiper('#hmImgListRight', {
                    direction: 'vertical',
                    allowTouchMove: false
                });
                this.hmTeamNameListRight = this.$swiper('#hmTeamNameListRight', {
                    direction: 'vertical',
                    allowTouchMove: false
                });
                this.hmTeamOddListRight = this.$swiper('#hmTeamOddListRight', {
                    direction: 'vertical',
                    allowTouchMove: false
                });

                this.hmMainSlider.controller.control = this.hmTeamNameListLeft;
                this.hmTeamNameListLeft.controller.control = this.hmImgListLeft;
                this.hmImgListLeft.controller.control = this.hmTeamOddListLeft;
                this.hmTeamOddListLeft.controller.control = this.hmPlayBtnList;
                this.hmPlayBtnList.controller.control = this.hmTimeList;
                this.hmTimeList.controller.control = this.hmImgListRight;
                this.hmImgListRight.controller.control = this.hmTeamNameListRight;
                this.hmTeamNameListRight.controller.control = this.hmTeamOddListRight;
            }
        }
    },
    mounted() {
        this.$nextTick(async () => {
            this.initSlider();
        });
    }
};
</script>
