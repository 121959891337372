export const PRIZE_OPTIONS = {
    D_10: 10, // 10,000
    D_20: 20, // 20,000
    D_50: 50, // 50,000
    D_5TR: 5000, // 5,000.000
    LUCKY: 'lucky', // lucky
    GUESS: 'guess' // guess
};
export const PRIZE_OPTIONS_SEAGAME = {
    D_10: 10,
    D_30: 30,
    D_100: 100,
    D_200: 200,
    D_500: 500,
    D_1TR: 1000,
    LUCKY: 'lucky',
    GOLD: 1,
    SILVER: 2,
    COPPER: 3,
    REFUND: 4
};

export const listCard = [
    {
        key: 'qatar',
        name: 'Qatar',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/qatar.png'
    },
    {
        key: 'ecuador',
        name: 'Ecuador',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/ecuador.png'
    },
    {
        key: 'senegal',
        name: 'Senegal',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/senegal.png'
    },
    {
        key: 'netherlands',
        name: 'Netherlands',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/netherlands.png'
    },
    {
        key: 'england',
        name: 'England',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/england.png'
    },
    {
        key: 'iran',
        name: 'IR Iran',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/iran.png'
    },
    {
        key: 'usa',
        name: 'USA',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/usa.png'
    },
    {
        key: 'wales',
        name: 'Wales',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/wales.png'
    },
    {
        key: 'argentina',
        name: 'Argentina',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/argentina.png'
    },
    {
        key: 'ksa',
        name: 'KSA',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/ksa.png'
    },
    {
        key: 'mexico',
        name: 'Mexico',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/mexico.png'
    },
    {
        key: 'poland',
        name: 'Poland',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/poland.png'
    },
    {
        key: 'france',
        name: 'France',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/france.png'
    },
    {
        key: 'australia',
        name: 'Australia',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/australia.png'
    },
    {
        key: 'denmark',
        name: 'Denmark',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/denmark.png'
    },
    {
        key: 'tunisia',
        name: 'Tunisia',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/tunisia.png'
    },
    {
        key: 'spain',
        name: 'Spain',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/spain.png'
    },
    {
        key: 'costa_rica',
        name: 'Costa Rica',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/costa_rica.png'
    },
    {
        key: 'germany',
        name: 'Germany',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/germany.png'
    },
    {
        key: 'japan',
        name: 'Japan',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/japan.png'
    },
    {
        key: 'belgium',
        name: 'Belgium',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/belgium.png'
    },
    {
        key: 'canada',
        name: 'Canada',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/canada.png'
    },
    {
        key: 'morocco',
        name: 'Morocco',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/morocco.png'
    },
    {
        key: 'croatia',
        name: 'Croatia',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/croatia.png'
    },
    {
        key: 'brazil',
        name: 'Brazil',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/brazil.png'
    },
    {
        key: 'serbia',
        name: 'Serbia',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/serbia.png'
    },
    {
        key: 'switzerland',
        name: 'Switzerland',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/switzerland.png'
    },
    {
        key: 'cameroon',
        name: 'Cameroon',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/cameroon.png'
    },
    {
        key: 'portugal',
        name: 'Portugal',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/portugal.png'
    },
    {
        key: 'ghana',
        name: 'Ghana',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/ghana.png'
    },
    {
        key: 'uruguay',
        name: 'Uruguay',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/uruguay.png'
    },
    {
        key: 'korea',
        name: 'Korea',
        number: '0',
        logo: '/assets/img/event/goal-scorer/team/korea.png'
    }
];

export const SHORT_DATE_FORMAT = 'DD-MM-YYYY';
