<template>
  <main class="main-content">
      <slot/>
  </main>
</template>

<script>

export default {
    props: {
        index: {
            type: String,
            default: ''
        },
        loginRequired: {
            type: Boolean,
            default: false
        },
        iframeDisplay: {
            type: Boolean,
            default: false
        },
        init: {
            type: Function,
            default: () => {}
        }
    },
    head() {
        return {};
    },
    computed: {

    },
    methods: {

    },
    mounted() {
        this.$nextTick(async () => {
            const user = this.$store.state.user;
            if (this.loginRequired && user == null) {
                this.$router.push('/');
                this.$alertLoginRequired();
            } else {
                // set current tab
                this.$store.commit('mutate', {
                    property: 'currentTab',
                    with: this.index
                });
                this.init();
                this.$store.commit('mutate', {
                    property: 'iframeDisplay',
                    with: this.iframeDisplay
                });
            }
        });
    }
};
</script>
